<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <div class="container-fluid">
          <div class="row bottom_header w-100 mx-0 mt-4">
            <div class="w-100 p-0">
              <div class="container-fluid tab-panel p-0">
                <div class="card w-100">
                  <div class="card-body p-0">
                    <div>
                      <w-chartoverview
                        :chartSummary="salseStatics"
                      ></w-chartoverview>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Marketing ROI -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-6 fit-content border_right">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Marketing ROI
                      </div>
                      <w-columnchart :chartData="marketingRio" />
                    </div>
                    <div class="col-6 row">
                      <div class="col-6 fit-content p-0">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Short term ROI
                        </div>
                        <pie-chart :series="shortTerm" />
                      </div>
                      <div class="col-6 fit-content p-0">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Long term ROI
                        </div>
                        <pie-chart :series="longTerm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Investment vs ROI -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <!-- <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Investment vs ROI
                      </div>-->
                      <w-columnchart :chartData="investRio" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Digital -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Digital
                      </div>
                      <button
                        class="hide-breakdown btn-default float-right"
                        @click="getDigitalBreakdown"
                      >
                        <i
                          class="fas fa-eye"
                          style="align-items: left"
                          v-if="digitalBreakdown"
                        ></i>
                        <img
                          src="@/assets/Icons/hide-password.svg"
                          alt=""
                          v-if="!digitalBreakdown"
                        />
                        Breakdown
                      </button>
                      <w-columnchart
                        :chartData="digital"
                        @columnClick="clickedDigital($event)"
                      />
                      <div
                        v-if="digitalBreakdown"
                        class="border_top_divider"
                        :style="{
                          '--left': valueAddedDigital + '%',
                        }"
                      >
                        <div
                          class="card-header font-large ProximaNovaBold content-center m-2"
                          style="
                            background: transparent;
                            border: 0px !important;
                            justify-content: space-between;
                          "
                        >
                          <div>{{ digitalTitle.text }}</div>

                          <div
                            class="
                              dropdown-breakdown
                              float-right
                              position-relative
                            "
                          >
                            <w-dropdown
                              :options="digitaldropdown"
                              :placeHolder="'Select'"
                              class="mr-3"
                              dropdownWidth="100%"
                              bgColor="#fff"
                              labelColor="#727988"
                              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                              :selectedOption="digitalTitle"
                              @input="selectedDigitalDropdown($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <w-columnchart :chartData="digitalMedia" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Content -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Content
                      </div>
                      <button
                        class="hide-breakdown btn-default float-right"
                        @click="getContentBreakdown"
                      >
                        <i
                          class="fas fa-eye"
                          style="align-items: left"
                          v-if="contentBreakdown"
                        ></i>
                        <img
                          src="@/assets/Icons/hide-password.svg"
                          alt=""
                          v-if="!contentBreakdown"
                        />
                        Breakdown
                      </button>
                      <w-columnchart
                        :chartData="content"
                        @columnClick="clickedContent($event)"
                      />
                      <div
                        v-if="contentBreakdown"
                        class="border_top_divider"
                        :style="{
                          '--left': valueAdded + '%',
                        }"
                      >
                        <div
                          class="card-header font-large ProximaNovaBold content-center m-2"
                          style="
                            justify-content: space-between;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          <div>{{ contentTitle.text }}</div>

                          <div
                            class="
                              dropdown-breakdown
                              float-right
                              position-relative
                            "
                          >
                            <w-dropdown
                              :options="contentdropdown"
                              :placeHolder="'Select'"
                              class="mr-3"
                              dropdownWidth="100%"
                              bgColor="#fff"
                              :selectedOption="contentTitle"
                              labelColor="#727988"
                              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                              @input="selectedContentDropdown($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <w-columnchart :chartData="contentMedia" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import { DigitalPoemServices } from "@/services/DigitalPoemServices.js";
const digitalPoemService = new DigitalPoemServices();

export default {
  name: "Summary",
  components: {
    "w-dropdown": Dropdown,
    // "w-tab": Tab,
    "w-chartoverview": ChartOverview,
    "w-columnchart": ColumnChart,
    PieChart,
  },
  data() {
    return {
      valueAdded: 0,
      valueAddedDigital: 0,
      digitalBreakdown: false,
      contentBreakdown: false,
      longTerm: [
        {
          name: "Long-Term",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      shortTerm: [
        {
          name: "ShortTerm",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      digitalTitle: "",
      digitaldropdown: [
        {
          text: "Linkedin",
          id: "1",
          iconHTML: '<i class="fab fa-linkedin"></i>',
        },
        {
          text: "Facebook",
          id: "2",
          iconHTML: '<i class="fab fa-facebook"></i>',
        },
        {
          text: "Instagram",
          id: "3",
          iconHTML: '<i class="fab fa-instagram"></i>',
        },
        {
          text: "Twitter",
          id: "4",
          iconHTML: '<i class="fab fa-twitter"></i>',
        },
      ],
      contentTitle: "",
      contentdropdown: [
        { text: "Owned content", id: "1" },
        { text: "Paid Media", id: "2" },
      ],
      activeTab: "ROI summary",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI summary",
        },
      ],
      salseStatics: [],
      marketingRio: {
        data: [
          {
            name: "Base Sales",
            data: [0, 360],
          },
          {
            name: "Short Term ROI",
            data: [200, 240],
          },
          {
            name: "Long Term ROI",
            data: [0, 240],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#536dfe"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      investRio: {
        data: [
          {
            name: "Investment",
            data: [30, 60, 30, 12, 30, 43, 50],
          },
          {
            name: "ROI",
            data: [60, 15, 50, 50, 20, 30, 45],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Investment vs ROI",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
      },
      digital: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        xAxis: {
          categories: [
            "Google Ads",
            "Facebook",
            "Programmatic",
            "Publishers",
            "Instagram",
            "Twitter",
          ],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedDigital(data.point.category);
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#536dfe"],
        subtitle: {},
        legend: {},
      },
      content: {
        data: [
          {
            name: "Investment",
            data: [30, 43],
          },
          {
            name: "ROI",
            data: [48, 12],
          },
        ],
        xAxis: {
          categories: ["Paid Media", "Owned Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedContent(data.point.category);
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#ffab40"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      digitalMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50],
          },
        ],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        subtitle: {},
        legend: {},
        color: ["#40c4ff", "#536dfe"],
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50],
          },
        ],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        subtitle: {},
        legend: {},
        color: ["#40c4ff", "#ffab40"],
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      market: "",
      colors: [
        "#18ffff",
        "#ffab40",
        "#536dfe",
        "#61f9d4",
        "#ff4081",
        "#ffd740",
        "#40c4ff",
        "#536dfe",
        "#68eeac",
        "#e53935",
      ],
    };
  },
  created() {
    this.salseStatics = [
      { name: "Marketing ROI", value: "5.7x", className: this.classess[0],image:"Marketing ROI.svg" },
      { name: "Investment", value: "36.23", className: this.classess[1] ,image:"Investment.svg"},
      { name: "ROI", value: "207.0", className: this.classess[2] ,image:"ROI.svg"},
      { name: "Short-Term", value: "145.0", className: this.classess[3] ,image:"Short-Term.svg"},
      { name: "Long-Term", value: "62.0", className: this.classess[4],image:"Long-Term.svg" },
    ];
    this.market = JSON.parse(sessionStorage.getItem("kpiMarket")).id;
    this.getMarketingROI();
    this.getShortTermRoi();
    this.getLongTerRoi();
    this.getInvestmentROI();
    this.getDigitalData();
    this.getContentData();
  },
  methods: {
    findIndexPosition() {
      this.valueAdded = 0;
      let ind = this.content.xAxis.categories.findIndex(
        (obj) => obj === this.contentTitle.text
      );
      var slotedPosition = 100 / this.content.xAxis.categories.length;
      var middle = slotedPosition / 2;
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          this.valueAdded += slotedPosition;
          if (i === ind - 1) {
            this.valueAdded += middle;
          }
        }
      } else {
        this.valueAdded = slotedPosition / 2;
      }
    },
    findIndexPositionDigital() {
      this.valueAddedDigital = 0;
      let ind = this.digital.xAxis.categories.findIndex(
        (obj) => obj === this.digitalTitle.text
      );
      var slotedPosition = 100 / this.digital.xAxis.categories.length;
      var middle = slotedPosition / 2;
      if (ind > 0) {
        for (var i = 0; i < ind; i++) {
          this.valueAddedDigital += slotedPosition;
          if (i === ind - 1) {
            this.valueAddedDigital += middle;
          }
        }
      } else {
        this.valueAddedDigital = slotedPosition / 2;
      }
    },
    getMarketingROI() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "marketingroi-digitalpoem",
          this.market
        )
        .then((res) => {
          this.marketingRio.data[0].data = [];
          this.marketingRio.data[1].data = [];
          this.marketingRio.data[2].data = [];
          this.marketingRio.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.marketingRio.xAxis.categories.push(res[i].name);
            this.marketingRio.data[0].data.push(res[i].base);
            this.marketingRio.data[1].data.push(res[i].short);
            this.marketingRio.data[2].data.push(res[i].long);
          }
        });
    },
    getShortTermRoi() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "shortterm-digitalpoem",
          this.market
        )
        .then((res) => {
          this.shortTerm[0].data = [];
          for (var i = 0; i < res.length; i++) {
            if (i === 0) {
              this.shortTerm[0].data.push({
                name: res[i].name,
                y: res[i].value,
                sliced: true,
                selected: true,
                color: this.colors[i],
              });
            } else {
              this.shortTerm[0].data.push({
                name: res[i].name,
                y: res[i].value,
                color: this.colors[i],
              });
            }
          }
        });
    },
    getLongTerRoi() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "longterm-digitalpoem",
          this.market
        )
        .then((res) => {
          this.longTerm[0].data = [];
          for (var i = 0; i < res.length; i++) {
            if (i === 0) {
              this.longTerm[0].data.push({
                name: res[i].name,
                y: res[i].value,
                sliced: true,
                selected: true,
                color: this.colors[i],
              });
            } else {
              this.longTerm[0].data.push({
                name: res[i].name,
                y: res[i].value,
                color: this.colors[i],
              });
            }
          }
        });
    },
    getInvestmentROI() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "investment and roi-digitalpoem",
          this.market
        )
        .then((res) => {
          this.investRio.data[0].data = [];
          this.investRio.data[1].data = [];
          this.investRio.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.investRio.data[0].data.push(res[i].Investment);
            this.investRio.data[1].data.push(res[i].ROI);
            this.investRio.xAxis.categories.push(res[i].name);
          }
        });
    },
    getDigitalData() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "investment and roi digital-digitalpoem",
          this.market
        )
        .then((res) => {
          this.digital.data[0].data = [];
          this.digital.data[1].data = [];
          this.digital.xAxis.categories = [];
          this.digitaldropdown = [];
          for (var i = 0; i < res.length; i++) {
            this.digital.data[0].data.push(res[i].Investment);
            this.digital.data[1].data.push(res[i].ROI);
            this.digital.xAxis.categories.push(res[i].name);
            this.digitaldropdown.push({
              text: res[i].name,
              id: res[i].name,
              iconHTML:
                '<i class="fab fa-' + res[i].name.toLowerCase() + "></i>",
            });
            if (i === res.length - 1) {
              this.digitalTitle = this.digitaldropdown[0];
            }
          }
        });
    },
    getContentData() {
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "investment and roi content-digitalpoem",
          this.market
        )
        .then((res) => {
          this.content.data[0].data = [];
          this.content.data[1].data = [];
          this.content.xAxis.categories = [];
          this.contentdropdown = [];
          for (var i = 0; i < res.length; i++) {
            if (res[i].name === "Paid Media") {
              this.content.data[0].data.push(res[i].Investment);
              this.content.data[1].data.push(res[i].ROI);
              this.content.xAxis.categories.push("Paid Content");
              this.contentdropdown.push({
                text: "Paid Content",
                id: "Paid Content",
                iconHTML:
                  '<i class="fab fa-' + "Paid Content".toLowerCase() + "></i>",
              });
            } else {
              this.content.data[0].data.push(res[i].Investment);
              this.content.data[1].data.push(res[i].ROI);
              this.content.xAxis.categories.push(res[i].name);
              this.contentdropdown.push({
                text: res[i].name,
                id: res[i].name,
                iconHTML:
                  '<i class="fab fa-' + res[i].name.toLowerCase() + "></i>",
              });
            }
            if (i === res.length - 1) {
              this.contentTitle = this.contentdropdown[0];
            }
          }
        });
    },
    getContentBreakdown() {
      this.contentBreakdown = !this.contentBreakdown;
      if (!this.contentBreakdown) {
        return;
      }
      this.getContentThirdLevel();
    },
    getDigitalBreakdown() {
      this.digitalBreakdown = !this.digitalBreakdown;
      if (!this.digitalBreakdown) {
        return;
      }
      this.getDigitalThirdLevel();
    },
    clickedDigital(data) {
      this.digitalTitle = {
        text: data,
        id: data,
        iconHTML: '<i class="fab fa-' + data.toLowerCase() + "></i>",
      };
      this.digitalBreakdown = true;
      this.getDigitalThirdLevel();
    },
    clickedContent(data) {
      this.contentTitle = {
        text: data,
        id: data,
        iconHTML: '<i class="fab fa-' + data.toLowerCase() + "></i>",
      };
      this.contentBreakdown = true;
      this.getContentThirdLevel();
    },
    getDigitalThirdLevel() {
      this.findIndexPositionDigital();
      var title = this.digitalTitle.text.toLowerCase().replace(/\s/g, "");
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "digital" + title + "-digitalpoem",
          this.market
        )
        .then((res) => {
          if (res.value.length === undefined) {
            return;
          }
          this.digitalMedia.data[0].data = [];
          this.digitalMedia.data[1].data = [];
          this.digitalMedia.xAxis.categories = [];
          for (var i = 0; i < res.value.length; i++) {
            this.digitalMedia.data[0].data.push(res.value[i].Investment);
            this.digitalMedia.data[1].data.push(res.value[i].ROI);
            this.digitalMedia.xAxis.categories.push(res.value[i].name);
          }
        });
    },
    getContentThirdLevel() {
      this.findIndexPosition();
      var title;
      if (this.contentTitle.text === "Paid Media") {
        title = "paid";
      } else if (this.contentTitle.text === "Owned Content") {
        title = "owned";
      }
      // title = this.contentTitle.text.toLowerCase().replace(/\s/g, "");
      digitalPoemService
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "content" + title + "-digitalpoem",
          this.market
        )
        .then((res) => {
          if (res.value.length === undefined) {
            return;
          }
          this.contentMedia.data[0].data = [];
          this.contentMedia.data[1].data = [];
          this.contentMedia.xAxis.categories = [];
          for (var i = 0; i < res.value.length; i++) {
            this.contentMedia.data[0].data.push(res.value[i].Investment);
            this.contentMedia.data[1].data.push(res.value[i].ROI);
            this.contentMedia.xAxis.categories.push(res.value[i].name);
          }
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    selectedDigitalDropdown(e) {
      if (this.digitalTitle === e) {
        return;
      }
      this.digitalTitle = e;
      this.getDigitalThirdLevel();
    },
    selectedContentDropdown(e) {
      if (this.contentTitle === e) {
        return;
      }
      this.contentTitle = e;
      this.getContentThirdLevel();
    },
  },
};
</script>

<style scoped>
.card_body_padding {
  padding: 30px 24px !important;
}
.fit-content {
  height: fit-content;
}
.hide-breakdown {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: #f3f3f3;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
}
.border_right {
  border-right: 1px solid #ccc;
}
.border_right::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -11px;
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(134deg);
}
.border_top_divider {
  border-top: 1px solid #ccc;
}
>>> .rs-handle.rs-move {
  border: 11px solid #fff !important;
}
.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 47.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: var(--left);
}
.dropdown-breakdown {
  width: 210px;
  object-fit: contain;
  font-size: 15px;
  position: absolute;
  right: 15px;
}
.bottom_header {
  position: sticky;
  top: 80px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
</style>
