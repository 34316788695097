<template>
  <div>
    <div class="container-fluid">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <h5 class="sales-growth">Set Sales Growth</h5>
            <w-tab
              :tabName="saleGrowthtabs[0].category"
              :tabs="saleGrowthtabs"
              @activeTab="changeSaleGrowthTab($event)"
            >
            </w-tab>
          </div>
        </div>
        <SalesGrowth
          v-if="activeSaleGrowthTab === 'Revenue Based'"
          :sliderValue="sliderRevenueValue"
          :data="revenueData"
          :step="5"
          @sliderChangeHandle="revenueSliderValue"
          :handleColor="'#536dfe'"
        />
        <!--<SalesGrowth
          v-if="activeSaleGrowthTab === 'Budget Based'"
          :sliderValue="sliderBudgetValue"
          :data="budgetData"
          :step='5'
          @sliderChangeHandle="budgetSliderValue($event)"
        />-->
      </div>

      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12">
            <details class="">
              <Summary class="Additional-details">Additional Details</Summary>
              <div
                class="row align-items-center additonal-detail pt-5"
                v-for="(detail, index) in additionalDetails"
                :key="index"
              >
                <div class="col-lg-2 col-md-2 col-12">
                  <h6 class="ProductNew">{{ detail.name }}</h6>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="d-flex justify-center">
                    <div class="w-100">
                      <w-dropdown
                        :options="detail.dropdownList"
                        :placeHolder="'Select'"
                        :labelText="'Product'"
                        class=""
                        @input="selectedKPi($event, index)"
                      ></w-dropdown>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-12">
                  <div class="date-wraper d-flex w-100">
                    <div class="d-flex align-items-center w-100">
                      <w-datepicker
                        :selectedDate="detail.date"
                        :placeHolder="'Select'"
                        :labelText="'Date'"
                        class=""
                        @getDate="selectedProduct($event, index)"
                      ></w-datepicker>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <div>
                    <b-form-input
                      class="input-wrapper"
                      v-model="detail.value"
                      type="number"
                      placeholder="Value in numbers"
                      oninput="value=value.replace(/[^0-9]/g,'');"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>

      <div class="row w-100 m-0" style="justify-content: flex-end">
        <w-button
          style="width: 186px"
          :buttonLoader="'normal'"
          :buttonText="'Predict'"
          :isDisable="disablePredict"
          class="my-5"
          @buttonClicked="handlePredict"
          :class="{ resetPasswordLink: disablePredict }"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import DatePicker from "@/widgets/DatePicker.vue";
export default {
  props: [],
  components: {
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    SalesGrowth,
    "w-datepicker": DatePicker,
  },
  data() {
    return {
      disablePredict: true,
      categorizedSelectedConnectors: [],
      // stepName: ["Platform", "DataSource", "KPI"],
      saleGrowthtabs: [
        {
          category: "Revenue Based",
        },
        // {
        //   category: "Budget Based",
        // },
      ],
      activeSaleGrowthTab: "Revenue Based",
      sliderRevenueValue: 0,
      revenueData: [
        { name: "Last year Revenue", value: 568 },
        { name: "Expected Revenue", value: 568 },
      ],
      sliderBudgetValue: 0,
      budgetData: [
        { name: "Last year Budget", value: 568 },
        { name: "Expected Budget", value: 568 },
      ],
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          selectedValue: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "Modern Trade", id: "Modern Trade" },
            { text: "Traditional Trade", id: "Traditional Trade" },
            { text: "Online/Market Places", id: "Online/Market Places" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            {
              text: "New Consumer Promo Campaigns",
              id: "New Consumer Promo Campaigns",
            },
            { text: "Total Consumer Promos", id: "Total Consumer Promos" },
            { text: "Trade Promos", id: "Trade Promos" },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New Messaging Platforms", id: "New Messaging Platforms" },
            { text: "New Campaign Lunches", id: "New Campaign Lunches" },
            {
              text: "New Celebrity endorsements",
              id: "New Celebrity endorsements",
            },
          ],
          selectedValue: "",
          product: "",
          date: new Date(),
          value: "",
        },
      ],
    };
  },
  methods: {
    selectedKPi(e, index) {
      if (this.additionalDetails[index].selectedValue != e) {
        this.additionalDetails[index].selectedValue = e;
        console.log(e);
      }
    },
    revenueSliderValue(e) {
      if (e > 30 || isNaN(e)) {
        this.disablePredict = true;
        return;
      }
      this.disablePredict = false;
      var data = (e * this.revenueData[0].value) / 100;
      this.sliderRevenueValue = e;
      this.revenueData[1].value = data + this.revenueData[0].value;
    },
    budgetSliderValue(e) {
      if (e > 30) {
        this.disablePredict = true;
        return;
      }
      this.disablePredict = false;
      var data = (e * this.budgetData[0].value) / 100;
      this.sliderBudgetValue = e;
      this.budgetData[1].value = data + this.budgetData[0].value;
    },
    changeSaleGrowthTab(tabName) {
      this.activeSaleGrowthTab = tabName;
    },
    handlePredict() {
      sessionStorage.setItem("growthVal", this.sliderRevenueValue);
      sessionStorage.setItem(
        "expectedResult",
        JSON.stringify(this.revenueData)
      );
      // this.$router.push("/demo/digitalpoem/planning-result");
    },
    selectedProduct(e, index) {
      this.additionalDetails[index].date = e;
    },
  },
  created() {
    var growth = parseInt(sessionStorage.getItem("growthVal"));
    if (growth !== undefined && growth !== null) {
      this.sliderRevenueValue = growth;
      this.revenueSliderValue(growth);
    }
    sessionStorage.setItem("previousBudget", 36.23);
  },
};
</script>
<style scoped>
>>> .rs-handle.rs-move {
  border: 11px solid #fff;
}
.content-wrapper {
  margin-top: 24px;
  padding: 28px 23px 27px 24px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.date-wraper {
  height: 60px;
  background-color: #eff5ff;
}

.date-label-wrapper {
  width: 40%;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.date-label {
  margin: 0px 16px;
  padding: 6px 0px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #313846;
}

.line {
  width: 2px;
  height: 29px;
  background: #c6ccd6;
}

.date-input {
  height: 60px;
  width: 100%;
  font-size: 16px;
  padding: 0 20px;
}

.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}

input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
}

input[type="number"] {
  outline: none;
  box-shadow: none;
  font-family: ProximaNovaBold;
  font-size: 16px;
}

.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.card {
  display: block;
}

.future {
  width: 144px;
  height: 24px;
  margin: 0 48px 7px 180px;
  /* margin-left: 500px; */
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #050505;
}

.Path-8 {
  /* width: 40px;
  height: 1px; */
  margin: 0px 0 0 230px;
  /* object-fit: contain;
  border: solid 2px #050505; */
}

/* .Path-8new {
  margin: 0px 0 0 550px;
} */

/* .roi {
  width: 117px;
  height: 24px;
  margin: 0 0 7px -100px;
  font-family: ProximaNovaRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
} */

.sales-growth {
  width: 156px;
  height: 24px;
  margin: 0 303px 19px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
}
input::-webkit-inner-spin-button {
  display: none;
}
.Additional-details {
  width: 259px;
  height: 24px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  outline: none;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.Additional-details::marker {
  display: flex;
  float: right;
  line-height: center;
  justify-content: center;
}
</style>
